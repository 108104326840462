exports.onPreRouteUpdate = ({ location, prevLocation }) => {
  // Code to remove the script when navigating away from the specific page
  if (prevLocation && prevLocation.pathname.includes('offerte-aanvragen')) {
    const existingScript = document.getElementById('offerte-aanvragen-script')
    if (existingScript) {
      existingScript.remove()
    }
  }
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
  // Code to add the script when navigating to the specific page
  if (location.pathname.includes('offerte-aanvragen')) {
    const script = document.createElement('script')
    script.src = 'https://marketing.clearmind.nu/data/js/offerte-aanvragen.js'
    script.id = 'offerte-aanvragen-script'
    script.async = true

    document.body.appendChild(script)
  }
}
